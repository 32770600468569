export const getTime = (date: string | Date) => {
  if (!date) {
    return '';
  }
  const inputDate = new Date(date);

  const hh = inputDate.getHours();
  const mm = inputDate.getMinutes();

  return `${hh < 10 ? `0${hh}` : hh}:${mm < 10 ? `0${mm}` : mm}`;
};

export const getDate = (date: string | Date, type?: 'slash' | 'dot') => {
  if (!date) {
    return '';
  }
  const inputDate = new Date(date);

  const day = inputDate.getDate();
  const month = inputDate.getMonth() + 1;
  const year = inputDate.getFullYear();

  return type === 'dot'
    ? `${day < 10 ? `0${day}` : day}.${month < 10 ? `0${month}` : month}.${year}`
    : `${day < 10 ? `0${day}` : day}/${month < 10 ? `0${month}` : month}/${year}`;
};

export const getDateWithTime = (date: string | Date, type?: 'slash' | 'dot') => {
  if (!date) return '';
  return `${getDate(date, type || 'dot')} ${getTime(date)}`;
};

export const getTimeFromMinutes = (minutes: number) => {
  if (!minutes) return null;

  const hours = Math.floor(minutes / 60);
  const updatedMinutes = minutes - hours * 60;

  const hoursString = hours > 0 ? `${hours}h ` : '';
  const minutesString = updatedMinutes > 0 ? `${updatedMinutes}m` : '';

  return hoursString + minutesString;
};

const toHoursMinutesAndSecondsInDoubledNumbers = (seconds: number) => {
  if (!seconds) return { hours: '00', minutes: '00', seconds: '00' };

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Number((seconds % 60).toFixed(0));

  const hoursString = hours < 10 ? `0${hours}` : hours;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;
  const secondsString = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  return { hours: hoursString, minutes: minutesString, seconds: secondsString };
};

export const getTimeFromSeconds = (value: number, returnNullIfNoSeconds?: boolean) => {
  if (!value && returnNullIfNoSeconds) return null;

  const { hours, minutes, seconds } = toHoursMinutesAndSecondsInDoubledNumbers(value);

  return `${hours}h ${minutes}min ${seconds}s`;
};
