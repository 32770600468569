import React, { SVGProps } from 'react';

const TripleCopy: React.FC<SVGProps<SVGSVGElement>> = ({ stroke = '#141414', ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8 11.5V13.166C8 14.475 7.475 15 6.159 15H2.834C1.525 15 1 14.475 1 13.166V9.841C1 8.525 1.525 8 2.834 8H4.5V9.659C4.5 10.975 5.025 11.5 6.334 11.5H8Z"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 8V9.659C11.5 10.975 10.975 11.5 9.659 11.5H6.334C5.025 11.5 4.5 10.975 4.5 9.659V6.334C4.5 5.025 5.025 4.5 6.334 4.5H8V6.159C8 7.475 8.525 8 9.834 8H11.5Z"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 2.834V6.159C15 7.475 14.475 8 13.159 8H9.834C8.525 8 8 7.475 8 6.159V2.834C8 1.525 8.525 1 9.834 1H13.159C14.475 1 15 1.525 15 2.834Z"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TripleCopy;
