import { Tooltip } from '@mui/material';
import { ComponentType, SVGProps } from 'react';
import s from './action-item.module.scss';

type Props = {
  text: string;
  enable?: boolean;
  visible?: boolean;
  className?: string;
  tooltipText?: string;
  color?: 'blue' | 'red';
  withLeftDivider?: boolean;
  enableWithoutVisualEffect?: boolean;
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  onClick: VoidFunction;
};

const ActionItem = ({
  Icon,
  text,
  enable,
  tooltipText,
  className = '',
  color = 'blue',
  visible = true,
  withLeftDivider = true,
  enableWithoutVisualEffect,
  onClick,
}: Props) => {
  const handleOnClick = () => {
    if (enable || enableWithoutVisualEffect) onClick();
  };

  return (
    <Tooltip placement="top" disableHoverListener={!tooltipText} title={tooltipText} classes={{ popper: s.tooltip }}>
      <div
        data-visible={visible}
        data-with-left-divider={withLeftDivider}
        className={`
          ${s.action_item} 
          ${enable ? s[color] : ''}
          ${enableWithoutVisualEffect ? s.enabled_without_visual_effect : ''}
          ${className}
        `}
        onClick={handleOnClick}
      >
        {Icon && <Icon className={s.icon} />}
        <div>{text}</div>
      </div>
    </Tooltip>
  );
};

export default ActionItem;
