import { FC, SVGProps } from 'react';

export const Calendar4Icon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.33398 1.33398V3.33398" stroke="#141414" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.666 1.33398V3.33398" stroke="#141414" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M10.6667 14.674H6C2.66667 14.674 2 13.3007 2 10.554V6.43398C2 3.30065 3.11333 2.46065 5.33333 2.33398H10.6667C12.8867 2.45398 14 3.30065 14 6.43398V10.554"
      stroke="#141414"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M9.5 12.2324H2.16602" stroke="#141414" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.0508 12.8156L13.0733 13.6336C13.317 13.8286 13.6711 13.7971 13.8767 13.5622L15.5508 11.6489"
      stroke="#141414"
      strokeLinecap="round"
    />
    <path d="M8 5V7.77778" stroke="#FF3737" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 9.27783H8.00499" stroke="#FF3737" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
