import { FC } from 'react';
import { Dayjs } from 'dayjs';
import { Clear } from '@mui/icons-material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { ArrowLongLeftIcon } from 'icons/arrow-long-left';
import { IconButton, InputAdornment } from '@mui/material';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { MultiInputDateRangeField } from '@mui/x-date-pickers-pro/MultiInputDateRangeField';
import { useDateRangeHandler } from 'components/custom-calendar-layout/use-custom-calendar-layout';
import s from './custom-calendar-layout.module.scss';

type OwnProps = {
  value?: DateRange<Dayjs>;
  onChange: (dateRange: DateRange<Dayjs>) => void;
};

const separator = () => <ArrowLongLeftIcon className={s.separator_icon} />;

const CustomCalendarLayout: FC<OwnProps> = ({ value, onChange }) => {
  const { validValue, handleOnChange, handleClear } = useDateRangeHandler(onChange, value);

  return (
    <div className={s.container}>
      <div className={s.multi_input_container}>
        <MultiInputDateRangeField
          value={validValue}
          format="DD.MM.YYYY"
          slots={{ separator }}
          classes={{ root: s.multi_input }}
          onChange={(newValue) => handleOnChange(newValue)}
          slotProps={{
            textField: ({ position }) => ({
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleClear(position === 'start' ? 0 : 1)} size="small">
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
                placeholder: 'DD.MM.YYYY',
                classes: { root: s.input },
              },
            }),
          }}
        />
      </div>

      <DateRangeCalendar
        reduceAnimations
        value={validValue}
        className={s.date_picker}
        classes={{ monthContainer: s.date_header }}
        onChange={(newValue) => handleOnChange(newValue)}
        slotProps={{
          nextIconButton: { className: s.date_button },
          previousIconButton: { className: s.date_button },
        }}
      />
    </div>
  );
};

export default CustomCalendarLayout;
