import { Box } from '@mui/material';
import { Paths } from 'routes/paths';
import { PlusIcon } from 'icons/plus';
import { AppState } from 'redux/store';
import { getDateWithTime } from 'utils/time';
import { useParams } from 'react-router-dom';
import { EmptySlotIcon } from 'icons/empty-slot';
import { StatisticsIcon } from 'icons/statistics';
import { useDispatch, useSelector } from 'react-redux';
import { Handle, NodeProps, Position } from 'reactflow';
import { ProductionStatusEnum } from 'types/status-enums';
import { statuses } from 'components/status-selector/constants';
import { TextEllipsis } from 'components/text-ellipsis/text-ellipsis';
import {
  Actions as ProductionWorkflowActions,
  NestedWorkflowData as ProductionNestedWorkflowData,
} from 'pages/production-workflow/controllers/production-workflow.controller';
import { MultiLineEllipsis } from 'components/multiline-ellipsis/multiline-ellipsis';
import NodeWrapper from 'components/ui-new/production-react-flow/components/node-wrapper/node-wrapper';
import { NestedWorkflowData as FailedTasksNestedWorkflowData } from 'modules/manage-failed-tasks-modal/manage-failed-tasks.controller';
import s from './workflow-node.module.scss';

const WorkflowNode = ({ id, data }: NodeProps<ProductionNestedWorkflowData | FailedTasksNestedWorkflowData>) => {
  const dispatch = useDispatch();
  const params = useParams();

  const isTaskPage = 'page' in data && data.page === 'task';
  const isManageFailedTask = 'isForFail' in data;
  const statusTooltip =
    isManageFailedTask || (data.status !== ProductionStatusEnum.Done && data.status !== ProductionStatusEnum.Canceled)
      ? undefined
      : `${data.status === ProductionStatusEnum.Done ? 'Produced' : 'Canceled'} ${getDateWithTime(data.finishedAt)}`;

  const { edges } = useSelector((state: AppState) => {
    return isTaskPage ? state.manage_failed_tasks : state.production_workflow;
  });
  const users = useSelector((state: AppState) => {
    return state.production_workflow.users;
  });
  const mainWorkflowStatus = useSelector((state: AppState) => state.production_workflow.productionInfo.main_workflow_status);
  const { highlightedSlotId } = useSelector((state: AppState) => state.production_workflow);

  const hasSource = edges.some((edge) => edge.source === id);

  const isEmpty = !data.id;
  const isHighlighted = id === highlightedSlotId;
  const isUpdated = (data as ProductionNestedWorkflowData).wasChanged && !isEmpty;

  return (
    <NodeWrapper
      users={users}
      itemId={data.id}
      isEmpty={isEmpty}
      type="production"
      status={data.status}
      progress={data.progress}
      statusTooltip={statusTooltip}
      responsible={data.responsible}
      mainWorkflowStatus={mainWorkflowStatus}
      isForFailedOptions={isManageFailedTask}
      productionStatus={(data as ProductionNestedWorkflowData).productionStatus}
      issues={!isManageFailedTask ? (data as ProductionNestedWorkflowData).issues : undefined}
      onUpdate={(value) => dispatch(ProductionWorkflowActions.updateCanvasNodes(data.id, value, 'workflow'))}
    >
      <a
        target="_self"
        rel="noreferrer"
        data-is-empty={isEmpty}
        data-is-updated={isUpdated && !isEmpty}
        className={`${s.workflow_container} ${!isEmpty && s[statuses[data.status].className]}`}
        href={`${window.location.origin}${Paths.ProductionWorkflow}/${isEmpty ? params.id : data.id}`}
      >
        <div className={s.icon}>
          <StatisticsIcon />
        </div>
        {isEmpty ? (
          <div className={s.empty_slot} data-highlighted={isHighlighted}>
            <div className={s.inner_block}>
              <div className={s.icon_wrapper}>
                <EmptySlotIcon />
              </div>
              <p className={s.text}>Empty slot</p>
            </div>
          </div>
        ) : (
          <Box width="100%">
            <div className={s.workflow_name_container}>
              <MultiLineEllipsis lineLimit={1} containerClassName={s.workflow_name}>
                {data.name}
              </MultiLineEllipsis>
              <div className={s.version}>V.{data.version}</div>
            </div>
            <div className={s.details_container}>
              <div className={s.details}>
                <span>Config.</span>
                <TextEllipsis className={s.value} tooltipTitle={data.configuration}>
                  {data.configuration}
                </TextEllipsis>
              </div>
              <div className={s.details}>
                <span>Workflow</span>
                <TextEllipsis className={s.value} tooltipTitle={data.workflow}>
                  {data.workflow}
                </TextEllipsis>
              </div>
            </div>
          </Box>
        )}
        <Handle
          id="b"
          type="source"
          isConnectable={false}
          position={Position.Right}
          className={`${s.handle} ${hasSource ? '' : s.handle_hide}`}
        >
          <PlusIcon width={10} height={10} color="#878b92" />
        </Handle>
      </a>
    </NodeWrapper>
  );
};

export default WorkflowNode;
