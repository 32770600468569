import { CssBaseline } from '@mui/material';
import RootModals from 'modules/root-modals/root-modals';
import { ToastContainer } from 'react-toastify';
import { Actions as AuthActions } from 'redux/auth.controller';
import { LicenseInfo } from '@mui/x-license-pro';
import store from 'redux/store';
import { RootRoutes } from 'routes/root.routes';
import { ReactFlowProvider } from 'reactflow';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ErrorBoundary } from 'modules/error-boundary/error-boundary';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLogRouterHistory } from 'routes/hooks/use-log-router-history';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
//! Do not delete: https://hesh-dev.atlassian.net/browse/HE-3451
// import { useChatWidget } from 'hooks/use-chat-widget';
import { useGoogleAnalytics } from 'hooks/use-google-analytics';
import PermissionGuardModal from './modules/permission-guard/permission-guard';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || '');

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

export default function App() {
  //! Do not delete: https://hesh-dev.atlassian.net/browse/HE-3451
  // useChatWidget();
  useLogRouterHistory(); // For local debugging purposes

  const { initializeGoogleAnalytics } = useGoogleAnalytics();
  initializeGoogleAnalytics();

  useEffect(() => {
    store.dispatch(AuthActions.checkIfUserAlreadyLoggedIn());
  }, []);

  return (
    <div data-testid="layout" className="layout">
      <ErrorBoundary>
        <ReactFlowProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <RootRoutes />

            <ToastContainer limit={3} />
            <PermissionGuardModal />
            <RootModals />
            <CssBaseline enableColorScheme />
          </LocalizationProvider>
        </ReactFlowProvider>
      </ErrorBoundary>
    </div>
  );
}
