import { FC, SVGProps } from 'react';

export const EditIcon: FC<SVGProps<SVGSVGElement>> = ({ stroke = '#141414', ...props }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_13181_10423)">
      <path
        d="M7.31498 1.33813H5.94876C2.53321 1.33813 1.16699 2.70436 1.16699 6.11991V10.2186C1.16699 13.6341 2.53321 15.0003 5.94876 15.0003H10.0474C13.463 15.0003 14.8292 13.6341 14.8292 10.2186V8.85235"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7576 2.03441L5.37469 7.41731C5.16976 7.62225 4.96483 8.02528 4.92384 8.31902L4.6301 10.3752C4.52081 11.1198 5.0468 11.6389 5.79139 11.5365L7.84755 11.2427C8.13446 11.2017 8.53749 10.9968 8.74926 10.7919L14.1322 5.40897C15.0612 4.47994 15.4984 3.40063 14.1322 2.03441C12.7659 0.668185 11.6866 1.10538 10.7576 2.03441Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.98584 2.80603C10.4435 4.43866 11.7209 5.71608 13.3604 6.1806"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_13181_10423">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
