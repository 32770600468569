// import { AppState } from 'redux/store';
import { AppState } from 'redux/store';
import { AccessLevel, Permission, UserPermissions } from 'services/permission.model';
import { PermissionsService } from 'services/permission.service';
import { StateController } from 'state-controller';
import { Actions as AuthActions } from 'redux/auth.controller';
import { SettingsActions } from 'pages/settings/settings.controller';

export type UserPermissionsState = {
  isLoading: boolean;
  permissions: UserPermissions;
};

const defaultPermissionsState: UserPermissions = {
  [Permission.webAdmin]: AccessLevel.noAccess,
  [Permission.webProductsView]: AccessLevel.access,
  [Permission.webProductsEdit]: AccessLevel.noAccess,
  [Permission.webProductionView]: AccessLevel.noAccess,
  [Permission.webProductionEdit]: AccessLevel.noAccess,
  [Permission.webTaskView]: AccessLevel.noAccess,
  [Permission.webTaskEditLevel]: AccessLevel.noAccess,
  [Permission.webManageFailedTasks]: AccessLevel.noAccess,
  [Permission.webDepartmentsViewEdit]: AccessLevel.noAccess,
  [Permission.webUsersViewEdit]: AccessLevel.noAccess,
  [Permission.webSettingsViewEdit]: AccessLevel.noAccess,
  [Permission.webAnalyticsView]: AccessLevel.noAccess,
  [Permission.webManageTaskRewardsAfterReportingPeriod]: AccessLevel.noAccess,
  [Permission.mobileMyTasksViewEdit]: AccessLevel.access,
  [Permission.mobileViewOtherUsersTasksLevel]: AccessLevel.noAccess,
  [Permission.mobileEditOtherUsersTasksLevel]: AccessLevel.noAccess,
  [Permission.mobileChangeTasksPriority]: AccessLevel.noAccess,
  [Permission.mobileManageTasksStatusesTimer]: AccessLevel.noAccess,
  [Permission.mobileTasksStatusManagementLevel]: AccessLevel.performer,
  [Permission.mobileViewSalaryOfOtherUsersLevel]: AccessLevel.noAccess,
  [Permission.mobileManageFailedTasks]: AccessLevel.noAccess,
};

const defaultState: UserPermissionsState = {
  isLoading: true,
  permissions: defaultPermissionsState,
};

const stateController = new StateController<UserPermissionsState>('USER_PERMISSIONS', defaultState);

export class Actions {
  public static initPermissions() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const userId = getState().user.id;
        const permissions = await PermissionsService.getPermissionsByUserId(userId);

        const currentPermissionsState = getState().userPermissions.permissions;
        const updatedPermissions = permissions.reduce((acc, item) => {
          return {
            ...acc,
            [item.permission as string]: item.value as unknown,
          };
        }, currentPermissionsState);

        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            permissions: updatedPermissions,
          })),
        );
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static onPermissionChange<T extends Permission>(permissionName: T, value: UserPermissions[T]) {
    return async (dispatch, getState: () => AppState) => {
      const pervState = { ...getState().userPermissions.permissions };
      const userId = getState().user.id;
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          permissions: {
            ...prev.permissions,
            [permissionName]: value,
          },
        })),
      );

      try {
        await PermissionsService.updatePermissionsByUserId({
          user_id: userId,
          permission: permissionName,
          value: value as AccessLevel,
        });

        // if action is applied to current user, reload permissions
        if (getState().auth.user?.id === userId) {
          await dispatch(AuthActions.loadCurrentUserData());
          await dispatch(SettingsActions.init(true));
        }
      } catch (error) {
        // revert changes if failed
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            permissions: pervState,
          })),
        );
        throw error;
      }
    };
  }
}

export class Selectors {
  public static canChangePermissionsForOtherUsers(state: AppState) {
    const userId = state.auth.user?.id;
    const targedUserId = state.user.id;
    const isAdmin = state.auth.user?.user_permissions[Permission.webAdmin];

    return isAdmin === AccessLevel.access || userId === targedUserId;
  }
}

export const reducer = stateController.getReducer();
