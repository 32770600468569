import {
  TaskRewards,
  ManageOrderT,
  TaskInfoModel,
  UpdateTaskBody,
  CreateTaskBody,
  FailReasonsModel,
  ManageFailedTasksBody,
  UpdateTaskRewardsBody,
  CreateUpdateTaskResponse,
  ManageFailedTasksResponse,
} from 'services/production-task.model';
import { IdName } from 'types/common-types';
import { PaginateResponse } from 'types/paginate-response';
import { buildQueryString } from 'utils/build-query-string';
import { baseAxiosInstance } from '../axios-config';

export class ProductionTaskService {
  static async getTaskInfo(taskId: string) {
    const { data } = await baseAxiosInstance.get<TaskInfoModel>(`/tasks/info/${taskId}`);
    return data;
  }

  static async getAllTasksKeys(search?: string, skip?: number, take?: number) {
    const queryParams = buildQueryString({ search, skip, take });

    const { data } = await baseAxiosInstance.get(`/tasks/task-keys?${queryParams}`);

    return data;
  }

  static async createTask(body: CreateTaskBody) {
    const { data } = await baseAxiosInstance.post<CreateUpdateTaskResponse>(`/tasks/create`, body);
    return data;
  }

  static async updateTask(taskId: string, body: UpdateTaskBody) {
    const { data } = await baseAxiosInstance.put<CreateUpdateTaskResponse>(`/tasks/${taskId}`, body);
    return data;
  }

  static async updateTaskRewards(taskId: string, body: UpdateTaskRewardsBody) {
    const { data } = await baseAxiosInstance.put<TaskRewards>(`/task-rewards/details/${taskId}`, body);

    return data;
  }

  static async manageTaskOrder(body: ManageOrderT) {
    const { data } = await baseAxiosInstance.post<CreateUpdateTaskResponse>(`/tasks/manage-order`, body);
    return data;
  }

  static async manageFailedTask(body: ManageFailedTasksBody) {
    const { data } = await baseAxiosInstance.post<ManageFailedTasksResponse>(`/tasks/manage-failed-tasks`, body);
    return data;
  }

  static async getAllFailReasons() {
    const { data } = await baseAxiosInstance.get<FailReasonsModel[]>(`/fail-reason/all`);
    return data;
  }

  static async getFailReasons(params: { search?: string; skip?: number; take?: number }) {
    const { data } = await baseAxiosInstance.get<FailReasonsModel[]>(`/fail-reason`, { params });
    return data;
  }

  static async deleteTask(id: string) {
    const { data } = await baseAxiosInstance.delete<CreateUpdateTaskResponse>(`/tasks/${id}`);
    return data;
  }

  static async getAllTaskNames(search: string = '', skip: number = 0, take: number = 3) {
    const queryParams = buildQueryString({ search, skip, take });

    const { data } = await baseAxiosInstance.get<PaginateResponse<IdName>>(`/tasks/names?${queryParams}`);

    return data;
  }

  static async getTaskRewards(taskId: string) {
    const { data } = await baseAxiosInstance.get<TaskRewards>(`/tasks/${taskId}/rewards`);

    return data;
  }
}
