import { FC, SVGProps } from 'react';

export const CalendarIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="#7C828A" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.83342 0.666504C5.29365 0.666504 5.66675 1.0396 5.66675 1.49984V2.33317H12.3334V1.49984C12.3334 1.0396 12.7065 0.666504 13.1667 0.666504C13.627 0.666504 14.0001 1.0396 14.0001 1.49984V2.33317H14.8334C16.2141 2.33317 17.3334 3.45246 17.3334 4.83317V14.8332C17.3334 16.2139 16.2141 17.3332 14.8334 17.3332H3.16675C1.78604 17.3332 0.666748 16.2139 0.666748 14.8332V4.83317C0.666748 3.45246 1.78604 2.33317 3.16675 2.33317H4.00008V1.49984C4.00008 1.0396 4.37318 0.666504 4.83342 0.666504ZM12.3334 3.99984V4.83317C12.3334 5.29341 12.7065 5.6665 13.1667 5.6665C13.627 5.6665 14.0001 5.29341 14.0001 4.83317V3.99984H14.8334C15.2937 3.99984 15.6667 4.37293 15.6667 4.83317V7.33317H2.33341V4.83317C2.33341 4.37293 2.70651 3.99984 3.16675 3.99984H4.00008V4.83317C4.00008 5.29341 4.37318 5.6665 4.83342 5.6665C5.29365 5.6665 5.66675 5.29341 5.66675 4.83317V3.99984H12.3334ZM2.33341 8.99984V14.8332C2.33341 15.2934 2.70651 15.6665 3.16675 15.6665H14.8334C15.2937 15.6665 15.6667 15.2934 15.6667 14.8332V8.99984H2.33341Z"
    />
  </svg>
);
