import { StateController } from 'state-controller';
import { PageName } from 'types/common-enums';
import { debounce } from 'utils/debounce';
import { Actions as ProductSearchActions } from 'pages/product-search/product-search.controller';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { MODALS } from 'modules/root-modals/modals';
import { notify } from 'notifications';
import { ProductCategory } from 'services/product-categories.model';
import { AccessLevel, Permission } from 'services/permission.model';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { Actions as ProductsActions } from 'pages/products/products.controller';
import { Actions as ProductsCategoriesActions } from 'pages/products/products-categories.controller';
import { AppState } from 'redux/store';
import { Tag } from 'services/product-tags.model';
import { IdName, PaginationData } from 'types/common-types';
import FireMinusIcon from 'icons/fire-minus';
import { ProductsService } from 'services/products.service';
import { RelatedProductResponse } from 'services/products.model';
import { getDeleteProductMessages } from 'pages/products/modules/get-delete-product-messages/get-delete-product-messages';
import { BanIcon } from 'icons/ban-icon';
import { ProductCategoriesService } from 'services/product-categories.service';
import { ActiveTickIcon } from 'icons/active-tick';
import s from './all-product.module.scss';

export const ALL_PRODUCTS_ID = '197faefb-da76-4df5-8e2f-ba436e5a9415';

export enum ProductsModalsNameEnum {
  Rename = 'renameModal',
  Move = 'moveModal',
  ManageTags = 'manageTagsModal',
  AddEntity = 'addEntityModal',
  RelatedProduct = 'relatedProductModal',
}

export type EntityVariants = 'product' | 'category' | 'multiselect' | 'deactivatedProduct' | 'emptyCategory' | '';

export type ProductsModalsState = {
  [ProductsModalsNameEnum.Rename]: {
    isOpen: boolean;
    entityVariant: EntityVariants;
    entityName: string;
    entityId: string;
    data: {
      name: string;
    };
  };

  [ProductsModalsNameEnum.Move]: {
    options: any[];
    isOpen: boolean;
    entityId: string;
    isOptionsLoading: boolean;
    entityVariant: EntityVariants;
    paginationMeta: PaginationData;
    data: {
      value: IdName | null;
    };
  };

  [ProductsModalsNameEnum.ManageTags]: {
    isOpen: boolean;
    entityName: string;
    entityId: string;
    data: {
      tags: Tag[];
    };
  };

  [ProductsModalsNameEnum.RelatedProduct]: {
    isOpen: boolean;
    productName: string;
    productVersion: string;
    relatedProduct: RelatedProductResponse[];
  };

  [ProductsModalsNameEnum.AddEntity]: {
    isOpen: boolean;
    entityVariant: EntityVariants;
    data: {
      name: string;
    };
  };
};

const defaultState: ProductsModalsState = {
  renameModal: {
    isOpen: false,
    entityVariant: '',
    entityName: '',
    entityId: '',
    data: {
      name: '',
    },
  },

  relatedProductModal: {
    isOpen: false,
    productName: '',
    productVersion: '',
    relatedProduct: [],
  },

  moveModal: {
    options: [],
    entityId: '',
    isOpen: false,
    entityVariant: '',
    isOptionsLoading: false,
    data: {
      value: null,
    },
    paginationMeta: {
      next: 0,
      total: 0,
      perPage: 20,
      lastPage: 0,
      currentPage: 1,
    },
  },

  manageTagsModal: {
    isOpen: false,
    entityName: '',
    entityId: '',
    data: {
      tags: [],
    },
  },

  addEntityModal: {
    isOpen: false,
    entityVariant: '',
    data: {
      name: '',
    },
  },
};

const stateController = new StateController<ProductsModalsState>('ALL_PRODUCTS_MODALS', defaultState);
export class Actions {
  public static openRenameModal(entityVariant: EntityVariants, entityId: string, entityName: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          renameModal: {
            ...prev.renameModal,
            isOpen: true,
            entityName,
            entityVariant,
            entityId,
            data: { name: entityName },
          },
        })),
      );
    };
  }

  public static openRemoveCategoryModal(categoryId: string, categoryName: string) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const canDeleteCategory = await ProductCategoriesService.canDeleteProductCategory(categoryId);

      if (canDeleteCategory.hasChildren && canDeleteCategory.canBeDeleted) {
        dispatch(
          ModalActions.openModal<DeleteConfirmationOwnProps>({
            id: MODALS.CONFIRM,
            props: {
              title: 'Delete?',
              text: (
                <span style={{ color: '#050505', fontWeight: '400', fontSize: '14px' }}>
                  Deleting the <strong>{categoryName}</strong> category will also delete all nested products and categories. Are
                  you sure you want to delete the <strong>{categoryName}</strong> category?
                </span>
              ),
              icon: <FireMinusIcon />,
              actionText: <span>Delete</span>,
              action: () => {
                dispatch(ProductsCategoriesActions.removeCategory(categoryId));
              },
            },
          }),
        );
      } else if (canDeleteCategory.canBeDeleted) {
        dispatch(
          ModalActions.openModal<DeleteConfirmationOwnProps>({
            id: MODALS.CONFIRM,
            props: {
              title: 'Delete?',
              text: (
                <span style={{ color: '#050505', fontWeight: '400', fontSize: '14px' }}>
                  Are you sure you want to delete the category <strong>{categoryName}</strong>?
                </span>
              ),
              icon: <FireMinusIcon />,
              actionText: <span>Delete</span>,
              action: () => {
                dispatch(ProductsCategoriesActions.removeCategory(categoryId));
              },
            },
          }),
        );
      } else {
        dispatch(
          ModalActions.openModal<DeleteConfirmationOwnProps>({
            id: MODALS.CONFIRM,
            props: {
              title: 'Delete not possible',
              text: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                  <span style={{ color: '#050505', fontWeight: '400', fontSize: '14px' }}>
                    Deletion of the category <strong>{categoryName}</strong> not possible:
                  </span>
                  <span style={{ color: '#050505', fontWeight: '500', fontSize: '14px' }}>
                    Category contains products which cannot be deleted because of one of following reasons:
                  </span>
                  <span style={{ color: '#050505', fontWeight: '400', fontSize: '14px' }}>
                    - Product was produced and its SKU is used in warehouse <br />- Product or its nested entities (workflow and
                    task templates) are edited by other users. <br /> - Product used as component in other products
                  </span>
                </div>
              ),
              cancelButtonText: 'Close',
              icon: <BanIcon />,
            },
          }),
        );
      }
    };
  }

  public static openRemoveProductModal(entityId: string, entityName: string, entityVersion: string, page: PageName) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const canDeleteProduct = await ProductsService.canDeleteProduct(entityId);

      const removeProduct = {
        allProducts: () => dispatch(ProductsActions.removeProduct(entityId)),
        productSearch: () => dispatch(ProductSearchActions.removeProduct(entityId)),
      };

      if (canDeleteProduct.canBeDeleted) {
        dispatch(
          ModalActions.openModal<DeleteConfirmationOwnProps>({
            id: MODALS.CONFIRM,
            props: {
              title: 'Delete?',
              text: (
                <span style={{ color: '#050505', fontWeight: '400', fontSize: '14px' }}>
                  Removing the <strong>{entityName}</strong> product will make it impossible to put it into production. Are you
                  sure you want to delete <strong>{entityName}</strong> product?
                </span>
              ),
              icon: <FireMinusIcon />,
              actionText: <span>Delete</span>,
              action: () => removeProduct[page](),
            },
          }),
        );
      } else {
        dispatch(
          ModalActions.openModal<DeleteConfirmationOwnProps>({
            id: MODALS.CONFIRM,
            props: {
              title: 'Delete not possible',
              text: (
                <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                  <span style={{ color: '#050505', fontWeight: '400', fontSize: '14px' }}>
                    Deletion of the product <strong>{entityName}</strong> not possible:
                  </span>
                  {getDeleteProductMessages(canDeleteProduct.reasons, () =>
                    dispatch(Actions.openRelationProductModal(entityId, entityName, entityVersion)),
                  )}
                </div>
              ),
              cancelButtonText: 'Close',
              icon: <BanIcon />,
            },
          }),
        );
      }
    };
  }

  public static openRelationProductModal(entityId: string, productName: string, productVersion: string) {
    return async (dispatch) => {
      const related = await ProductsService.getRelatedProduct(entityId);
      if (!related.length) return;
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          relatedProductModal: {
            ...prev.relatedProductModal,
            isOpen: true,
            relatedProduct: related,
            productName,
            productVersion,
          },
        })),
      );
    };
  }

  public static openDeactivateCategoryModal(entityId: string, entityName: string) {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            backgroundColor: '#FFCECE',
            title: 'Inactivate category?',
            text: (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Closing access to <strong>{entityName}</strong> will prevent all products in this category from being selected
                  for production or being used as components in other products.
                </span>
                <span className={s.text}>
                  Are you sure you want to inactivate <strong>{entityName}</strong>?
                </span>
              </div>
            ),
            icon: <FireMinusIcon />,
            actionButtonColor: 'error',
            actionText: 'Inactivate',
            action: () => {
              dispatch(ProductsCategoriesActions.onCategoriesIsActiveChange(entityId, false));
            },
          },
        }),
      );
    };
  }

  public static openActivateCategoryModal(entityId: string, entityName: string) {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            backgroundColor: '#BCF4DE',
            title: 'Activate category?',
            text: (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Activating <strong>{entityName}</strong> will activate all products in this category and allow them to be
                  selected for production or used as components in other products.
                </span>
                <span className={s.text}>
                  Are you sure you want to activate <strong>{entityName}</strong>?
                </span>
              </div>
            ),
            icon: <ActiveTickIcon />,
            actionButtonColor: 'primary',
            actionText: 'Activate',
            action: () => {
              dispatch(ProductsCategoriesActions.onCategoriesIsActiveChange(entityId, true));
            },
          },
        }),
      );
    };
  }

  public static openActivateDeactivateProductModal(
    entityId: string,
    entityName: string,
    isEntityActive: boolean,
    entityVersion: string,
    page: PageName,
  ) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const activateProduct = {
        allProducts: () => dispatch(ProductsActions.onProductIsActiveChange(entityId, isEntityActive)),
        productSearch: () => dispatch(ProductSearchActions.onProductIsActiveChange(entityId, isEntityActive)),
      };

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: isEntityActive ? 'Inactivate product?' : 'Activate product?',
            text: isEntityActive ? (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Closing access to <strong>{entityName}</strong> will prevent this product, including all its versions,
                  configurations, and workflows, from being selected for production or used as a component in{' '}
                  <span
                    className={s.related_product}
                    onClick={() => {
                      dispatch(Actions.openRelationProductModal(entityId, entityName, entityVersion));
                    }}
                  >
                    related products.
                  </span>
                </span>
                <span className={s.text}>
                  Are you sure you want to inactivate <strong>{entityName}</strong>?
                </span>
              </div>
            ) : (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Opening access to <strong>{entityName}</strong>will allow this product to be selected for production or used as
                  a component in{' '}
                  <span
                    className={s.related_product}
                    onClick={() => {
                      dispatch(Actions.openRelationProductModal(entityId, entityName, entityVersion));
                    }}
                  >
                    related products.
                  </span>
                </span>
                <span className={s.text}>
                  Are you sure you want to activate <strong>{entityName}</strong>?
                </span>
              </div>
            ),
            icon: isEntityActive ? <FireMinusIcon /> : <ActiveTickIcon />,
            backgroundColor: isEntityActive ? '#FFCECE' : '#BCF4DE',
            withCloseButton: false,
            actionButtonColor: isEntityActive ? 'error' : 'primary',
            actionText: isEntityActive ? 'Inactivate' : 'Activate',
            action: () => activateProduct[page](),
          },
        }),
      );
    };
  }

  public static closeModal(modalName: ProductsModalsNameEnum) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          [modalName]: defaultState[modalName],
        })),
      );
    };
  }

  public static openAddEntityModal(entityVariant: EntityVariants) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          addEntityModal: {
            ...prev.addEntityModal,
            isOpen: true,
            entityVariant,
          },
        })),
      );
    };
  }

  public static openManageTagsModal(entityId: string, entityName: string, page: PageName) {
    return (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      let productTags: Tag[];

      switch (page) {
        case PageName.AllProducts:
          productTags = getState().products.products.items.find((item) => item.product_meta.id === entityId)?.tags || [];
          break;
        case PageName.ProductSearch:
          productTags = getState().productSearch.productItems.find((item) => item.product_meta.id === entityId)?.tags || [];
          break;
        default:
          break;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          manageTagsModal: {
            ...prev.manageTagsModal,
            isOpen: true,
            entityId,
            entityName,
            data: { tags: productTags },
          },
        })),
      );
    };
  }

  public static onChangeAddEntityNameField(name: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          addEntityModal: {
            ...prev.addEntityModal,
            data: { name },
          },
        })),
      );
    };
  }

  public static onRenameModalDataChange(data: { name: string }) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          renameModal: {
            ...prev.renameModal,
            data: { ...prev.renameModal.data, ...data },
          },
        })),
      );
    };
  }

  // ====== Move modal ==============================================================================================================

  public static openMoveModal(entityVariant: EntityVariants, targetId: string, page?: PageName, productMetaId?: string) {
    return async (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const id = getState().products?.categories?.activeCategory?.id;
      const name = getState().products?.categories?.activeCategory?.name || '';

      let parent: IdName;

      if (id) {
        parent = {
          id,
          name,
        };
      } else {
        parent = {
          id: ALL_PRODUCTS_ID,
          name: 'All products',
        };
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          moveModal: {
            ...prev.moveModal,
            isOpen: true,
            entityVariant,
            entityId: page === PageName.ProductSearch ? productMetaId : targetId,
            data: { value: parent },
          },
        })),
      );
    };
  }

  public static onChangeMoveModalData(newValue: IdName | null) {
    return async (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, moveModal: { ...prev.moveModal, data: { value: newValue } } })));
    };
  }

  public static loadCategories(value: string = '', isScroll: boolean = false, withoutDelay: boolean = false) {
    return async (dispatch, getState: () => AppState) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            moveModal: {
              ...prev.moveModal,
              paginationMeta: {
                ...prev.moveModal.paginationMeta,
                next: 0,
              },
            },
          })),
        );
      }

      const { paginationMeta, entityId } = getState().products.productsModals.moveModal;
      if (isScroll && !paginationMeta.next) return;
      const delay = withoutDelay ? 0 : 500;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          moveModal: {
            ...prev.moveModal,
            isOptionsLoading: true,
          },
        })),
      );

      debounce(async () => {
        try {
          const { selectedCategoryIds } = getState().products.multiselect;

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              moveModal: {
                ...prev.moveModal,
                isOptionsLoading: true,
              },
            })),
          );

          const { data, meta } = await ProductCategoriesService.getProductCategories(
            value === 'All products' ? '' : value.trim(),
            paginationMeta.next || undefined,
            paginationMeta.perPage,
          );
          let filteredData: ProductCategory[] = [];
          if (selectedCategoryIds.length) {
            filteredData = data.filter((i) => !selectedCategoryIds.includes(i.id));
          } else {
            filteredData = data.filter((i) => i.id !== entityId);
          }

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              moveModal: {
                ...prev.moveModal,
                paginationMeta: meta,
                options: isScroll ? [...prev.moveModal.options, ...filteredData] : filteredData,
              },
            })),
          );
        } catch (error) {
          notify.error(error.message);
          throw error;
        } finally {
          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              moveModal: {
                ...prev.moveModal,
                isOptionsLoading: false,
              },
            })),
          );
        }
      }, delay);
    };
  }

  public static clearCategoriesOptions() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          moveModal: {
            ...prev.moveModal,
            options: [],
          },
        })),
      );
    };
  }
}

export class Selectors {
  public static isHasChild(state: AppState) {
    const { items } = state.products.categories;
    const { entityId } = state.products.productsModals.moveModal;
    const selectedCategory = items.find((category) => category.id === entityId);
    return selectedCategory?.productsCount !== 0 || selectedCategory?.subcategories.length !== 0;
  }
}

export const reducer = stateController.getReducer();
