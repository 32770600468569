import { FC, SVGProps } from 'react';

export const Filter3Icon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.5 3.49951H6.5" stroke="#292D32" strokeWidth="0.8" strokeLinecap="round" />
    <path d="M3 5.99951H8" stroke="#292D32" strokeWidth="0.8" strokeLinecap="round" />
    <path d="M5 8.49951H7" stroke="#292D32" strokeWidth="0.8" strokeLinecap="round" />
    <path
      d="M9.5 4.99951C10.3284 4.99951 11 4.32794 11 3.49951C11 2.67108 10.3284 1.99951 9.5 1.99951C8.67157 1.99951 8 2.67108 8 3.49951C8 4.32794 8.67157 4.99951 9.5 4.99951Z"
      fill="#0851FE"
    />
  </svg>
);
