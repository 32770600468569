import { COLORS } from 'components/manage-tags-modals/constants';
import { notify } from 'notifications';
import { DeleteProductionTagModalActions } from 'pages/production/controllers/delete-production-tag-modal.controller';
import { isTagChanged } from 'pages/production/controllers/helpers';
import { ManageProductionTagsModalActions } from 'pages/production/controllers/manage-production-tags-modal.controller';
import { GetStateFunction } from 'redux/store';
import { Paths } from 'routes/paths';
import { ProductionWorkflowTagModel } from 'services/production-workflow.model';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { StateController } from 'state-controller';

export type ProductionTagEditorArgs = {
  tag?: ProductionWorkflowTagModel;
  mode: 'create' | 'edit';
};

export type TagEditorState = {
  isOpen: boolean;
  isTagChanged: boolean;
  tag: ProductionWorkflowTagModel;
  initialTag: ProductionWorkflowTagModel;
  mode: ProductionTagEditorArgs['mode'];
};

const defaultTag: ProductionWorkflowTagModel = {
  id: '',
  name: '',
  created_at: '',
  color: COLORS['#FFF2F9'].bgColor,
};

const defaultState: TagEditorState = {
  isOpen: false,
  mode: 'create',
  isTagChanged: false,
  tag: defaultTag,
  initialTag: defaultTag,
};

const stateController = new StateController<TagEditorState>('TAG_EDITOR_MODAL', defaultState);

export class ProductionTagEditorActions {
  public static openModal({ tag, mode }: ProductionTagEditorArgs) {
    return async (dispatch) => {
      const initialTag = tag || defaultTag;

      dispatch(
        stateController.setState({
          mode,
          initialTag,
          isOpen: true,
          tag: initialTag,
          isTagChanged: false,
        }),
      );
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));
    };
  }

  public static handleChangeName(value: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { initialTag } = getState().production.tagEditorModal;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tag: { ...prev.tag, name: value },
          isTagChanged: isTagChanged({ ...prev.tag, name: value }, initialTag),
        })),
      );
    };
  }

  public static handleChangeColor(color: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { initialTag } = getState().production.tagEditorModal;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tag: { ...prev.tag, color },
          isTagChanged: isTagChanged({ ...prev.tag, color }, initialTag),
        })),
      );
    };
  }

  public static handleDelete(route: Paths) {
    return async (dispatch, getState: GetStateFunction) => {
      const { tag } = getState().production.tagEditorModal;

      try {
        const deletedTag = await ProductionWorkflowService.deleteProductionTag(tag.id);

        dispatch(ManageProductionTagsModalActions.deleteTag(deletedTag, route));
        dispatch(DeleteProductionTagModalActions.closeModal());
        dispatch(stateController.setState({ isOpen: false }));
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  public static handleSave(mode: 'create' | 'edit', route: Paths) {
    return async (dispatch, getState: GetStateFunction) => {
      const { tag, initialTag } = getState().production.tagEditorModal;

      if (mode === 'create') {
        const createdTag = await ProductionWorkflowService.createProductionTag(tag);

        dispatch(ManageProductionTagsModalActions.addTag(createdTag));
      } else {
        const updatedFields: Partial<ProductionWorkflowTagModel> = {};

        if (tag.name !== initialTag.name) updatedFields.name = tag.name;
        if (tag.color !== initialTag.color) updatedFields.color = tag.color;

        if (Object.keys(updatedFields).length > 0) {
          const updatedTag = await ProductionWorkflowService.updateProductionTag(tag.id, updatedFields);

          dispatch(ManageProductionTagsModalActions.updateTag(updatedTag, route));
        }
      }

      dispatch(stateController.setState({ isOpen: false }));
    };
  }
}

export const productionTagEditorModalReducer = stateController.getReducer();
