import { FC, SVGProps } from 'react';

export const UnlockIcon2: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.333 14.6666H4.66634C1.99967 14.6666 1.33301 14 1.33301 11.3333V9.99996C1.33301 7.33329 1.99967 6.66663 4.66634 6.66663H11.333C13.9997 6.66663 14.6663 7.33329 14.6663 9.99996V11.3333C14.6663 14 13.9997 14.6666 11.333 14.6666Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 6.66671V5.33337C4 3.12671 4.66667 1.33337 8 1.33337C10.4515 1.33337 11.3726 1.56899 12 3.03066"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99967 12.3333C8.92015 12.3333 9.66634 11.5871 9.66634 10.6667C9.66634 9.74619 8.92015 9 7.99967 9C7.0792 9 6.33301 9.74619 6.33301 10.6667C6.33301 11.5871 7.0792 12.3333 7.99967 12.3333Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
