import { FC, SVGProps } from 'react';

export const DoneIcon2: FC<SVGProps<SVGSVGElement>> = ({ stroke = '#2FC488', ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.1667 2.78136C12.941 2.07231 11.5179 1.6665 10 1.6665C5.39765 1.6665 1.66669 5.39746 1.66669 9.99984C1.66669 14.6022 5.39765 18.3332 10 18.3332C14.6024 18.3332 18.3334 14.6022 18.3334 9.99984C18.3334 8.97374 18.1479 7.99095 17.8087 7.08317M5.83335 8.33317L8.77204 10.6841C9.46147 11.2357 10.4614 11.1539 11.052 10.4976L17.5 3.33317"
      stroke={stroke}
      strokeWidth="1.1"
      strokeLinecap="round"
    />
  </svg>
);
