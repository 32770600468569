// eslint-disable-next-line no-restricted-imports
import { DateRange } from '@mui/lab';
import { AssignmentType } from 'services/workflow-task-template-responsibility.model';
import { TaskTypeEnum } from 'types/common-enums';
import { IdName, MetaT, SortOrderOption } from 'types/common-types';
import { PriorityEnum } from 'types/priority-enums';
import { ProductionStatusEnum, TaskStatusEnum } from 'types/status-enums';
import { Dayjs } from 'dayjs';
import { PopoverProps } from '@mui/material';
import { ShowCompletedPeriodEnum } from 'services/production-workflow.model';
import { TaskSlotsT } from 'services/task-table.model';

export enum TasksFiltersEnum {
  // Product
  ProductName = 'product_name',
  ProductVersion = 'product_version',
  ProductVariant = 'product_variant',
  RootProductName = 'root_product_name',
  RootProductVersion = 'root_product_version',
  RootProductVariant = 'root_product_variant',
  ProductConfiguration = 'product_configuration',
  RootProductConfiguration = 'root_product_configuration',

  // Production
  ProductionKey = 'production_key',
  ProductionStatus = 'production_status',
  RootProductionKey = 'root_production_key',
  ProductionDeadline = 'production_deadline',
  RootProductionDeadline = 'root_production_deadline',

  // Order
  Client = 'client',
  OrderKey = 'order_key',
  PrimaryClient = 'primary_client',
  ExternalOrderNumber = 'external_order_number',
  MarketPlaceOrderNumber = 'marketplace_order_number',

  // Time
  DueDate = 'due_date',

  // Task Details
  TaskKey = 'task_key',
  TaskStatus = 'status',
  IsFailed = 'is_failed',
  FailedAt = 'failed_at',
  TaskType = 'task_type',
  TaskName = 'task_name',
  IsInQueue = 'is_in_queue',
  TaskPriority = 'priority',
  ReportingPeriod = 'reporting_period',
  ReasonForFailure = 'reason_for_failure',

  // Assignment Details
  Assignee = 'assignee',
  AssigneeType = 'assignment_type',
  AssigneePosition = 'assignee_position',
  AssigneeDepartment = 'assignee_department',

  // Warnings
  AssigneeRequired = 'assignee_required',
  TimeLimitExceeded = 'time_limit_exceeded',

  // View popup
  ShowCompleted = 'show_completed',
}

export enum FilterSettingEnum {
  Contains = 'contains',
  Blank = 'blank',
}

export type ColumnOrderItemT = {
  colId: string;
  hide: boolean;
};

export type TasksFiltersDataT = {
  meta?: MetaT;
  value: number | string | IdName | IdName[];
  options?: IdName[];
  selectedOptions?: IdName[];
  filterSetting?: FilterSettingEnum;
};

export type DropDownProps = {
  isOpen: boolean;
  anchorEl: PopoverProps['anchorEl'];
  includeUnassignedOption?: boolean;
  onClose: PopoverProps['onClose'];
};

export type ValueOptionsSelectedOptionsT = { value: string; options: IdName[]; selectedOptions: IdName[] };

export type ValueOptionsSelectedOptionsWithSettingT = ValueOptionsSelectedOptionsT & { filterSetting: FilterSettingEnum };

export type TasksFiltersT = {
  filters: {
    // Production
    [TasksFiltersEnum.ProductionDeadline]: { value: DateRange<Dayjs> };
    [TasksFiltersEnum.RootProductionDeadline]: { value: DateRange<Dayjs> };
    [TasksFiltersEnum.ProductionStatus]: {
      value: string;
      options: IdName[];
      selectedOptions: ProductionStatusEnum[];
    };
    [TasksFiltersEnum.ShowCompleted]: {
      radioValue: ShowCompletedPeriodEnum;
      value: IdName;
      options: IdName[];
    };

    // Task
    [TasksFiltersEnum.IsInQueue]: {
      value: string;
      options: IdName[];
      selectedOptions: string[];
    };
    [TasksFiltersEnum.IsFailed]: {
      value: string;
      options: IdName[];
      selectedOptions: string[];
    };
    [TasksFiltersEnum.FailedAt]: { value: DateRange<Dayjs> };
    [TasksFiltersEnum.ReportingPeriod]: { value: DateRange<Dayjs> };
    [TasksFiltersEnum.ReasonForFailure]: ValueOptionsSelectedOptionsT;
    [TasksFiltersEnum.TaskType]: { value: string; options: IdName[]; selectedOptions: TaskTypeEnum[] };
    [TasksFiltersEnum.TaskPriority]: { value: string; options: IdName[]; selectedOptions: PriorityEnum[] };
    [TasksFiltersEnum.TaskStatus]: { value: string; options: IdName[]; selectedOptions: TaskStatusEnum[] };

    // Assignment
    [TasksFiltersEnum.Assignee]: { value: string; options: IdName[]; selectedOptions: string[] };
    [TasksFiltersEnum.AssigneeType]: { value: string; options: IdName[]; selectedOptions: AssignmentType[] };

    // Warnings
    [TasksFiltersEnum.AssigneeRequired]: {
      value: string;
      options: IdName[];
      selectedOptions: string[];
    };
    [TasksFiltersEnum.TimeLimitExceeded]: {
      value: string;
      options: IdName[];
      selectedOptions: string[];
    };

    // Time
    [TasksFiltersEnum.DueDate]: { value: DateRange<Dayjs> };
  };
  queries: {
    // Product
    [TasksFiltersEnum.ProductName]: string;
    [TasksFiltersEnum.ProductVersion]: number;
    [TasksFiltersEnum.ProductVariant]: string;
    [TasksFiltersEnum.RootProductName]: string;
    [TasksFiltersEnum.RootProductVersion]: number;
    [TasksFiltersEnum.RootProductVariant]: string;
    [TasksFiltersEnum.ProductConfiguration]: string;
    [TasksFiltersEnum.RootProductConfiguration]: string;

    // Production
    [TasksFiltersEnum.ProductionKey]: string;
    [TasksFiltersEnum.RootProductionKey]: string;

    // Order
    [TasksFiltersEnum.Client]: string;
    [TasksFiltersEnum.OrderKey]: string;
    [TasksFiltersEnum.PrimaryClient]: string;
    [TasksFiltersEnum.ExternalOrderNumber]: string;
    [TasksFiltersEnum.MarketPlaceOrderNumber]: string;

    // Task
    [TasksFiltersEnum.TaskName]: string;
    [TasksFiltersEnum.TaskKey]: string;
    [TasksFiltersEnum.ReasonForFailure]: string;

    // Assignment
    [TasksFiltersEnum.AssigneePosition]: string;
    [TasksFiltersEnum.AssigneeDepartment]: string;
  };
  pins: {
    left: TasksFiltersEnum[];
    right: TasksFiltersEnum[];
  };
  sort: {
    orderBy: TasksFiltersEnum[];
    orderOption: SortOrderOption;
  };
};

export type ColumnSizesT = { [key in TasksFiltersEnum]: number } | null;
export type ManageSelectOrDeselectAllTasksArgs = { resetAll?: boolean };

export type TaskTableStateT = {
  id: string;
  // Product
  product_name: string;
  product_version: number;
  product_variant: string;
  product_configuration: string;
  root_product_name: string;
  root_product_version: number;
  root_product_variant: string;
  root_product_configuration: string;

  // Production
  production_key: string;
  root_production_key: string;
  production_deadline: string;
  production_progress: number;
  root_production_deadline: string;
  production_workflow_id: string;
  root_production_workflow_id: string;
  production_status: ProductionStatusEnum;

  // Order
  client: string;
  order_key: string;
  primary_client: string;
  external_order_number: string;
  marketplace_order_number: string;

  // Task Details
  task_key: string;
  task_name: string;
  is_in_queue: boolean;
  failed_at: string;
  is_failed: boolean;
  total_bonus: number;
  basic_reward: number;
  task_type: TaskTypeEnum;
  reason_for_failure: string;
  status: TaskStatusEnum;
  priority: PriorityEnum;
  reporting_period: { from: string; to: string };
  is_reporting_period_closed: boolean;

  // Assignment details
  assigned_at: IdName[];
  department_ids: string[];
  assignee_position: {
    id: string;
    name: string;
    slot_id: string;
  }[];
  assignee_department: {
    id: string;
    name: string;
    slot_id: string;
  }[];
  assignment_type: AssignmentType;
  assignee: {
    slot_id: string;
    taskAssignment: {
      user: {
        id: string;
        avatar_image_url: string;
        first_name: string;
        last_name: string;
      };
    };
    task_responsibility_id: string;
  }[];

  // Time
  time_limit: number;
  time_spent: number;
  created_at: string;

  // Warnings
  assignee_required: boolean;
  time_limit_exceeded: boolean;

  task_slots: TaskSlotsT[];
  is_assignment_in_progress: boolean;
};

export type PerformerItemT = {
  id: string;
  name: string;
  avatar: string;
  lastName: string;
  firstName: string;
};

type SelectedUserT = {
  id: string;
  avatar_image_url: string;
  first_name: string;
  last_name: string;
};

type SelectedTaskAssignmentT = {
  user: SelectedUserT;
};

export type SelectedTaskSlotT = {
  slot_id: string;
  taskAssignment?: SelectedTaskAssignmentT;
  task_responsibility_id: string;
};

export type SelectedTaskT = {
  id: string;
  departmentIds: string[];
  slots: SelectedTaskSlotT[];
};
