import { FC, SVGProps } from 'react';

export const NoImageIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.0662 14.133L15.4579 8.04137C14.5745 5.9747 12.9495 5.89137 11.8579 7.85803L10.2829 10.6997C9.48286 12.1414 7.9912 12.2664 6.95787 10.9747L6.77453 10.7414C5.69953 9.39137 4.18286 9.55803 3.40786 11.0997L1.97453 13.9747C0.966198 15.9747 2.42453 18.333 4.65786 18.333H15.2912C17.4579 18.333 18.9162 16.1247 18.0662 14.133Z"
      stroke="#878B92"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.80811 6.66748C7.18882 6.66748 8.30811 5.54819 8.30811 4.16748C8.30811 2.78677 7.18882 1.66748 5.80811 1.66748C4.42739 1.66748 3.30811 2.78677 3.30811 4.16748C3.30811 5.54819 4.42739 6.66748 5.80811 6.66748Z"
      stroke="#878B92"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
