import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { getValidDateValues } from 'pages/production/controllers/helpers';

export const useDateRangeHandler = (onChange: (val: DateRange<Dayjs>) => void, value?: DateRange<Dayjs>) => {
  const validValue = getValidDateValues(value);

  const handleOnChange = (val: DateRange<Dayjs>) => {
    const newValue = getValidDateValues(val);
    const isArraysEqual = validValue.every((v, index) => v === newValue[index]);

    if (isArraysEqual) return;

    onChange(newValue);
  };

  const handleClear = (index: 0 | 1) => {
    const newValue = [...validValue] as DateRange<Dayjs>;
    newValue[index] = null;

    handleOnChange(newValue);
  };

  return { validValue, handleOnChange, handleClear };
};
