import { ReactNode } from 'react';
import { InfoIcon } from 'icons/info';
import { ArrowsIcon } from 'icons/arrows';
import { LockIcon } from 'icons/lock-icon';
import { WarningIcon } from 'icons/warning';
import { IdName } from 'types/common-types';
import Checkbox from 'components/checkbox/checkbox';
import Tag from 'components/tag-outline/tag-outline';
import useInQueueLogic from 'hooks/use-in-queue-logic';
import { User, UserShortModel } from 'services/user.model';
import { ConnectorSplitRightIcon } from 'icons/connector-split-right';
import StatusSelector from 'components/status-selector/status-selector';
import { ProductionStatusEnum, TaskStatusEnum } from 'types/status-enums';
import { LightTooltip } from 'components/ui-new/light-tooltip/light-tooltip';
import {
  NestedWorkflowData,
  TaskData as ProductionWorkflowTaskData,
} from 'pages/production-workflow/controllers/production-workflow.controller';
import CustomProgress from 'components/ui-new/custom-progress/custom-progress';
import { Box, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { ProductionIssueEnum, ProductionWorkflowResponsibleT } from 'services/production-workflow.model';
import DropdownUserSearchSelector from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';
import { TaskData as ManageFailedTasksTaskData } from 'modules/manage-failed-tasks-modal/manage-failed-tasks.controller';
import MultipleAssigneeSelector, {
  MultipleAssigneeSelectorSlotsT,
} from 'components/ui-new/multiple-assignee-selector/multiple-assignee-selector';
import s from './node-wrapper.module.scss';

type Props = {
  users: User[];
  itemId: string;
  isFail?: boolean;
  issues?: IdName[];
  disable?: boolean;
  progress?: number;
  isEmpty?: boolean;
  children: ReactNode;
  is_in_queue?: boolean;
  isWithMoney?: boolean;
  statusTooltip?: string;
  isTaskReopened?: boolean;
  isEditPermitted?: boolean;
  type: 'production' | 'task';
  isForFailedOptions?: boolean;
  isReportingPeriodClosed?: boolean;
  productionStatus?: ProductionStatusEnum;
  isManageInQueueStatusPermitted?: boolean;
  isAllowedUndoChangingTaskStatus?: boolean;
  mainWorkflowStatus?: ProductionStatusEnum;
  userSlots?: MultipleAssigneeSelectorSlotsT[];
  status: ProductionStatusEnum | TaskStatusEnum;
  responsible?: ProductionWorkflowResponsibleT | null;
  handleFailTask?: (values: Partial<ManageFailedTasksTaskData>) => void;
  onUpdate: (value: Partial<ProductionWorkflowTaskData | NestedWorkflowData>) => void;
  handleTaskUsers?: (taskId: string, user: UserShortModel, slotId: string, type: 'assign' | 'unassign') => void;
};

const NodeWrapper = ({
  type,
  users,
  isFail,
  issues,
  status,
  itemId,
  isEmpty,
  disable,
  progress,
  children,
  userSlots,
  is_in_queue,
  isWithMoney,
  responsible,
  statusTooltip,
  isTaskReopened,
  productionStatus,
  isForFailedOptions,
  mainWorkflowStatus,
  isEditPermitted = true,
  isReportingPeriodClosed,
  isManageInQueueStatusPermitted,
  isAllowedUndoChangingTaskStatus,
  onUpdate,
  handleFailTask,
  handleTaskUsers,
}: Props) => {
  const {
    wrapperRef,
    currentStatus,
    inQueueTooltip,
    isShowLockIcon,
    isShowDimmedInQueue,
    handleInQueueUpdate,
    setIsShowDimmedInQueue,
    handleIsShowDimmedInQueue,
    hideInQueueMarkIfNotHoveredOverElement,
  } = useInQueueLogic({
    type,
    isInQueue: !!is_in_queue,
    isManageInQueueStatusPermitted: !!isManageInQueueStatusPermitted,
    status: status as TaskStatusEnum,
    onUpdate,
  });
  const progressbarColor = progress === 100 ? 'green' : 'blue';
  const isAllowedChangingTaskStatus = isAllowedUndoChangingTaskStatus && !isReportingPeriodClosed;
  const isStatusSelectorDisabled =
    (isForFailedOptions && type === 'production') || !!statusTooltip || mainWorkflowStatus === ProductionStatusEnum.From_Stock;

  return (
    <div
      className={`${s.context_area} ${disable ? s.total_disable : ''}`}
      data-reopened={isTaskReopened}
      onMouseEnter={handleIsShowDimmedInQueue}
      onMouseLeave={() => setIsShowDimmedInQueue(false)}
      ref={wrapperRef}
    >
      <div data-align-right={isEmpty} className={`${s.controls_container} ${isForFailedOptions ? s.disable_events : ''}`}>
        {!disable && (
          <>
            {type === 'task' && (
              <MultipleAssigneeSelector
                slots={userSlots || []}
                isEditPermitted={isEditPermitted}
                isAvatarOnly
                onClose={hideInQueueMarkIfNotHoveredOverElement}
                assignUser={(user, slotId) => handleTaskUsers?.(itemId, user, slotId, 'assign')}
                unassignUser={(user, slotId) => handleTaskUsers?.(itemId, user, slotId, 'unassign')}
              />
            )}
            {type === 'production' && !isEmpty && (
              <Box className={s.avatar_container}>
                <DropdownUserSearchSelector
                  isAvatarOnly
                  users={users}
                  value={responsible?.id || ''}
                  currentlySelectedUser={responsible}
                  onSelect={(value, prev, selected) => onUpdate({ responsible: selected })}
                />
              </Box>
            )}
            {!!issues?.length && !isEmpty && (
              <LightTooltip
                className={s.tooltip}
                placement="top-start"
                title={
                  <>
                    {issues.map(({ id, name }) => (
                      <Stack key={id} direction="row" alignItems="center" gap="6px">
                        <div className={s.bullet_point} />
                        <Typography>{name}</Typography>
                      </Stack>
                    ))}
                  </>
                }
              >
                <div className={s.warning_wrapper}>
                  <Tag
                    color="orange"
                    className={s.badge}
                    iconLeft={
                      <Stack direction="row" alignItems="center" gap="3px">
                        <WarningIcon width={20} height={20} />
                        {issues.some((issue) => issue.id === ProductionIssueEnum.IssuesInNestedComponents) && (
                          <ConnectorSplitRightIcon width={13} height={13} />
                        )}
                      </Stack>
                    }
                  />
                </div>
              </LightTooltip>
            )}
            {isEmpty && (
              <LightTooltip className={s.tooltip} placement="top-start" title="Position of the removed component">
                <div className={s.position_badge_wrapper}>
                  <Tag
                    color="purple"
                    className={s.badge}
                    iconLeft={
                      <Stack direction="row" alignItems="center" gap="3px">
                        <ArrowsIcon />
                      </Stack>
                    }
                  />
                </div>
              </LightTooltip>
            )}
          </>
        )}
      </div>

      {children}

      <div className={s.controls_container}>
        {!disable && isForFailedOptions && type === 'task' && status === TaskStatusEnum.Done && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  size="medium"
                  color="error"
                  checked={isFail}
                  disabled={!isEditPermitted}
                  onChange={() => handleFailTask?.({ isFail: !isFail, isWithMoney: !isFail && isWithMoney })}
                />
              }
              label="Fail"
            />
            <Stack direction="row" alignItems="center" gap="6px">
              <FormControlLabel
                control={
                  <Switch
                    checked={isWithMoney}
                    disabled={!isEditPermitted}
                    onChange={() =>
                      handleFailTask?.({ isWithMoney: !isWithMoney, isFail: !isFail && !isWithMoney ? true : isFail })
                    }
                  />
                }
                label={
                  <Stack direction="row" alignItems="center" gap="4px">
                    <Typography>UAH</Typography>
                    <LightTooltip
                      className={s.tooltip}
                      placement="bottom-start"
                      title="A new task will be created to complete this job"
                    >
                      <div>
                        <InfoIcon height={12} width={12} />
                      </div>
                    </LightTooltip>
                  </Stack>
                }
              />
            </Stack>
          </>
        )}
        {((isForFailedOptions && type === 'production') || (!disable && !isForFailedOptions)) && !isEmpty && (
          <>
            <Stack direction="row" gap="4px">
              <StatusSelector
                status={status}
                optionsFor={type}
                toolTip={statusTooltip}
                tagClassName={s.status_tag}
                isEditPermitted={isEditPermitted}
                productionStatus={productionStatus}
                disableDropDown={isStatusSelectorDisabled}
                isBlockedLaunch={!isAllowedChangingTaskStatus && status === TaskStatusEnum.Done}
                onSelect={async (selectedStatus) => {
                  currentStatus.current = selectedStatus as TaskStatusEnum;
                  await onUpdate({ status: selectedStatus, responsible });
                }}
                onClose={hideInQueueMarkIfNotHoveredOverElement}
              />
              <LightTooltip
                title={inQueueTooltip}
                className={!isShowLockIcon ? s.hide_tooltip : ''}
                disableHoverListener={!isManageInQueueStatusPermitted}
              >
                <button
                  className={s.lock_wrapper}
                  type="button"
                  data-show={isShowLockIcon}
                  data-dimmed={isShowDimmedInQueue}
                  onClick={handleInQueueUpdate}
                >
                  <LockIcon />
                </button>
              </LightTooltip>
            </Stack>
            {progress !== undefined && (
              <div className={s.progress}>
                {progress}%
                <CustomProgress type={progressbarColor} variant="determinate" value={progress} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NodeWrapper;
