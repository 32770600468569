import { Paths } from 'routes/paths';
import { AppState } from 'redux/store';
import { notify } from 'notifications';
import { BanIcon } from 'icons/ban-icon';
import { debounce } from 'utils/debounce';
import FireMinusIcon from 'icons/fire-minus';
import { StateController } from 'state-controller';
import { MODALS } from 'modules/root-modals/modals';
import { DepartmentService } from 'services/department.service';
import { Department as DepartmentT } from 'services/department.model';
import { PositionSlotsService } from 'services/position-slots.service';
import { IdNameType, IdName, PaginationData } from 'types/common-types';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { Actions as MultiselectActions } from 'pages/products/multiselect.controller';
import { PositionSlotByDepartmentId as PositionItem } from 'services/position-slots.model';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { CandidatesForDeletionT } from 'pages/departments/types';

export const NEW_DEPARTMENT_ID = 'new';

export enum ItemType {
  Department = 'department',
  Position = 'position',
}

export type DepartmentItem = {
  id: string;
  name: string;
  usersImages: string[];
  users_count: number;
};
export type MoveModalState = {
  options: IdName[];
  isModalOpen: boolean;
  isProcessing: boolean;
  isOptionsLoading: boolean;
  selectedValue: IdName | null;
  paginationMeta: PaginationData;
  movableItem: DepartmentItem | PositionItem | null;
};
export type QuickSearchState = {
  items: any[];
  isFetching: boolean;
};

export type DepartmentsState = {
  isLoading: boolean;
  isDeleting: boolean;
  isSearchResultsFetching: boolean;
  moveModal: MoveModalState;
  renameModeItemId: string;
  lastAddedItemId: string;
  breadcrumbs: IdNameType[];
  quickSearch: QuickSearchState;
  positions: PositionItem[];
  departments: DepartmentItem[];
};

const defaultState: DepartmentsState = {
  isLoading: false,
  isDeleting: false,
  isSearchResultsFetching: false,
  renameModeItemId: '',
  lastAddedItemId: '',
  moveModal: {
    options: [],
    movableItem: null,
    isModalOpen: false,
    isProcessing: false,
    selectedValue: null,
    isOptionsLoading: false,
    paginationMeta: {
      next: 0,
      total: 0,
      perPage: 20,
      lastPage: 0,
      currentPage: 1,
    },
  },
  quickSearch: {
    items: [],
    isFetching: false,
  },
  breadcrumbs: [],
  positions: [],
  departments: [],
};

const stateController = new StateController<DepartmentsState>('DEPARTMENTS_STATE', defaultState);

export class Actions {
  public static loadData(departmentId: string, spinner: boolean = true) {
    return async (dispatch) => {
      try {
        if (spinner) dispatch(stateController.setState({ isLoading: true }));

        const departmentsData = await DepartmentService.getDepartamentWithChildren(departmentId);
        const positionsData = await PositionSlotsService.getPositionSlotsByDepartment(departmentId || departmentsData.path[0].id);

        const bread = departmentsData.path.map((i) => ({
          ...i,
          type: 'department',
        }));

        const positionsSorted = positionsData.sort((a, b) => a.name.localeCompare(b.name));
        const departmentsSorted = departmentsData.departments.sort((a, b) => a.name.localeCompare(b.name));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            breadcrumbs: bread,
            positions: positionsSorted,
            departments: departmentsSorted,
          })),
        );
      } finally {
        if (spinner) dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static navigateToDepartment(departmentId: string) {
    return (dispatch) => {
      dispatch(Actions.loadData(departmentId));
    };
  }

  public static openDeleteConfirmationModal(item?: PositionItem | DepartmentItem) {
    return async (dispatch, getState: () => AppState) => {
      let candidatesForDeletion: CandidatesForDeletionT[] = [];

      if (item) {
        let itemForDeletion = {
          id: item.id,
          name: item.name,
          type: ItemType.Department,
        };

        if ((item as PositionItem).positionType) {
          const i = item as PositionItem;
          itemForDeletion = {
            id: i.id,
            type: ItemType.Position,
            name: i.positionType.name,
          };
        }

        candidatesForDeletion = [itemForDeletion];
      } else {
        const { selectedPositionIds, selectedDepartmentIds } = getState().products.multiselect;
        const { departments, positions } = getState().departments;

        const selectedDepartments = departments
          .filter((i) => selectedDepartmentIds.includes(i.id))
          .map((d) => ({
            name: d.name,
            type: ItemType.Department,
            id: d.id,
          }));
        const selectedPositions = positions
          .filter((i) => selectedPositionIds.includes(i.id))
          .map((m) => ({
            name: m.positionType.name,
            type: ItemType.Position,
            id: m.id,
          }));
        candidatesForDeletion = selectedPositions.concat(selectedDepartments);
      }

      const departmentIds: string[] = [];
      const positionIds: string[] = [];

      candidatesForDeletion.forEach((c) => {
        if (c.type === ItemType.Department) {
          departmentIds.push(c.id);
        } else if (c.type === ItemType.Position) {
          positionIds.push(c.id);
        }
      });

      const canDeleteData = await DepartmentService.canDeleteDepartments(departmentIds);
      const isFooterHidden = canDeleteData.some((can) => Object.values(can).includes(false));

      const titleText = isFooterHidden ? 'Deleting of items is not available' : 'Delete items?';

      const text = isFooterHidden
        ? 'You can not delete the following items:'
        : 'Deleting a department or position will result in excluding all employees associated with them.Are you sure you want to delete the following items?';

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: titleText,
            text: (
              <>
                <div>
                  {text}
                  <ul style={{ marginTop: '4px' }}>
                    {candidatesForDeletion.map((d) => (
                      <li key={`${d.id}-${d.type}`} style={{ listStyleType: 'disc', marginLeft: 25 }}>
                        <strong>{d.name}</strong> <span>{d.type}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                {isFooterHidden && (
                  <div style={{ marginTop: '8px' }}>
                    Make sure that items are not participating in automatic or self-assignment task distribution settings.
                    Removing items will block the system from performing the assignment. You can check this information in the
                    &nbsp;
                    <span style={{ fontWeight: '600', color: '#0851FE', textDecoration: 'underline' }}>Task template report</span>
                  </div>
                )}
              </>
            ),
            icon: isFooterHidden ? <BanIcon /> : <FireMinusIcon />,
            withCloseButton: false,
            actionText: 'Delete',
            showFooter: !isFooterHidden,
            action: () => dispatch(Actions.deleteSelectedItems(departmentIds, positionIds)),
          },
        }),
      );
    };
  }

  public static deleteSelectedItems(departmentIds: string[], positionIds: string[]) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { breadcrumbs } = getState().departments;
        const departmentId = breadcrumbs[breadcrumbs.length - 1].id;

        if (departmentIds.length > 0) {
          await DepartmentService.deleteSeveralDepartments(departmentIds);
          dispatch(MultiselectActions.deselectAllDepartments());
        }
        if (positionIds.length > 0) {
          await PositionSlotsService.deleteSeveralPositionSlots(positionIds);
          dispatch(MultiselectActions.deselectAllPositions());
        }

        dispatch(Actions.loadData(departmentId, false));
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  public static closeRenameMode(isNew?: boolean) {
    return async (dispatch, getState: () => AppState) => {
      const ItemId = getState().departments.renameModeItemId;
      if (!ItemId) return;

      dispatch(stateController.setState({ renameModeItemId: '' }));

      if (isNew) {
        dispatch(Actions.removeDepartmentsFromList([NEW_DEPARTMENT_ID]));
      }
    };
  }

  public static createCandidateDepartment() {
    return async (dispatch) => {
      const candidate = {
        id: NEW_DEPARTMENT_ID,
        name: '',
        usersImages: [],
        users_count: 0,
      };
      dispatch(Actions.addDepartmentToList(candidate));
      setTimeout(() => {
        dispatch(stateController.setState({ renameModeItemId: NEW_DEPARTMENT_ID }));
      }, 0);
    };
  }

  public static createNewDepartment(name: string, id: string) {
    return async (dispatch, getState: () => AppState) => {
      const { breadcrumbs } = getState().departments;
      const parentId = id || breadcrumbs[breadcrumbs.length - 1].id;

      const newData = {
        name,
        work_time_from: 8,
        work_time_to: 17,
        timezone: 'Ukraine/Kyiv',
        parent_department_id: parentId,
        auto_pause_task_after_work_time: true,
      };

      const data = await DepartmentService.createDepartament(newData);

      await dispatch(Actions.loadData(parentId, false));
      dispatch(Actions.closeRenameMode());
      dispatch(Actions.setLastAddedItemId(data.id));
    };
  }

  public static renameDepartment(departmentId: string, newName: string) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return async (dispatch) => {
      try {
        const body = {
          name: newName,
          work_time_from: 8,
          work_time_to: 17,
          timezone: 'Ukraine/Kyiv',
          parent_department_id: undefined,
          auto_pause_task_after_work_time: undefined,
        };

        await DepartmentService.updateDepartament(departmentId, body);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            departments: prev.departments.map((item) => {
              if (item.id === departmentId) {
                return {
                  ...item,
                  name: newName,
                };
              }
              return item;
            }),
          })),
        );
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  public static setLastAddedItemId(lastAddedItemId: string) {
    return async (dispatch) => {
      await dispatch(stateController.setState({ lastAddedItemId }));
      setTimeout(() => dispatch(stateController.setState({ lastAddedItemId: '' })), 2000);
    };
  }

  public static addDepartmentToList(item: DepartmentItem) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          departments: [item, ...prev.departments],
        })),
      );
    };
  }

  public static addPositionToList(item: PositionItem) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          positions: [item, ...prev.positions],
        })),
      );
    };
  }

  public static removeDepartmentsFromList(ids: string[]) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          departments: prev.departments.filter((i) => !ids.includes(i.id)),
        })),
      );
    };
  }

  public static removePositionsFromList(ids: string[]) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          positions: prev.positions.filter((i) => !ids.includes(i.id)),
        })),
      );
    };
  }

  // ====== Move modal ==========================================================================================================

  public static openMoveModal(item?: PositionItem | DepartmentItem) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            moveModal: {
              ...prev.moveModal,
              isModalOpen: true,
              movableItem: item || null,
            },
          })),
        );
      } catch (err) {
        notify.error(err.message);
      }
    };
  }

  public static closeMoveModal() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          moveModal: defaultState.moveModal,
        })),
      );
    };
  }

  public static loadMoreDepartments(value: string = '', isScroll: boolean = false, withoutDelay: boolean = false) {
    return async (dispatch, getState: () => AppState) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            moveModal: {
              ...prev.moveModal,
              paginationMeta: {
                ...prev.moveModal.paginationMeta,
                next: 0,
              },
            },
          })),
        );
      }

      const { paginationMeta } = getState().departments.moveModal;
      if (isScroll && paginationMeta.currentPage >= paginationMeta.lastPage) return;
      const delay = withoutDelay ? 0 : 500;

      try {
        debounce(async () => {
          const { selectedDepartmentIds } = getState().products.multiselect;
          const { movableItem } = getState().departments.moveModal;

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              moveModal: {
                ...prev.moveModal,
                isOptionsLoading: true,
              },
            })),
          );

          const { data, meta } = await DepartmentService.getAllDepartaments(
            value.trim(),
            paginationMeta.next || undefined,
            paginationMeta.perPage,
          );

          let filteredData: DepartmentT[] = [];
          if (selectedDepartmentIds.length) {
            filteredData = data.filter((i) => !selectedDepartmentIds.includes(i.id));
          } else {
            filteredData = data.filter((i) => i.id !== movableItem?.id);
          }

          const options: IdName[] = filteredData.map((i) => ({
            id: i.id,
            name: i.name,
          }));

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              moveModal: {
                ...prev.moveModal,
                paginationMeta: meta,
                isOptionsLoading: false,
                options: isScroll ? [...prev.moveModal.options, ...options] : options,
              },
            })),
          );
        }, delay);
      } catch (error) {
        notify.error(error.message);
        throw error;
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            moveModal: {
              ...prev.moveModal,
              isOptionsLoading: false,
            },
          })),
        );
      }
    };
  }

  public static selectParentDepartment(value: IdName) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          moveModal: {
            ...prev.moveModal,
            selectedValue: value,
          },
        })),
      );
    };
  }

  public static moveItems() {
    return async (dispatch, getState: () => AppState) => {
      const { movableItem } = getState().departments.moveModal;
      const parentId = getState().departments.moveModal.selectedValue?.id;
      const { selectedPositionIds, selectedDepartmentIds } = getState().products.multiselect;
      const { breadcrumbs } = getState().departments;
      const departmentId = breadcrumbs[breadcrumbs.length - 1].id;

      if (!parentId) return;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            moveModal: {
              ...prev.moveModal,
              isProcessing: true,
            },
          })),
        );

        if (movableItem) {
          const { positionType } = movableItem as PositionItem;

          if (positionType) {
            const { id } = movableItem;
            await PositionSlotsService.updateParentDepartment(parentId, [id]);
          } else {
            const { id } = movableItem;
            await DepartmentService.updateParentDepartment(parentId, [id]);
          }
        } else {
          if (selectedDepartmentIds.length > 0) {
            await DepartmentService.updateParentDepartment(parentId, selectedDepartmentIds);
          }
          if (selectedPositionIds.length > 0) {
            await PositionSlotsService.updateParentDepartment(parentId, selectedPositionIds);
          }
        }

        await dispatch(Actions.loadData(departmentId, false));
        dispatch(MultiselectActions.deselectAllDepartments());
        dispatch(MultiselectActions.deselectAllPositions());
        dispatch(Actions.closeMoveModal());
      } catch (err) {
        notify.error(err.message);
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            moveModal: {
              ...prev.moveModal,
              isProcessing: false,
            },
          })),
        );
      }
    };
  }

  public static clearDepartmentsOptions() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          moveModal: {
            ...prev.moveModal,
            options: [],
          },
        })),
      );
    };
  }

  // ====== Quick search ========================================================================================================

  public static clearItemList() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          quickSearch: {
            ...prev.quickSearch,
            items: [],
          },
        })),
      );
    };
  }

  public static getItemsByKeyword(keyword: string) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            quickSearch: {
              ...prev.quickSearch,
              isFetching: true,
            },
          })),
        );

        const departmentsData = await DepartmentService.getAllDepartmentsWithPath(keyword);
        const positionsData = await PositionSlotsService.getAllPositionsWithPath(keyword);

        const mappedDepartments = departmentsData.map((item) => ({
          id: item.id,
          name: item.name,
          description: null,
          type: ItemType.Department,
          route: `${Paths.Departments}/${item.id}`,
          path: item.path.map((i) => i.name).join(' / '),
        }));

        const mappedPositions = positionsData.map((item) => ({
          id: item.id,
          description: item.name,
          type: ItemType.Position,
          name: item.positionType.name,
          path: item.path.map((i) => i.name).join(' / '),
          route: `${Paths.Departments}/${item.path[item.path.length - 1].id}`,
        }));

        const mappedItems = [...mappedDepartments, ...mappedPositions];

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            quickSearch: {
              ...prev.quickSearch,
              items: mappedItems,
            },
          })),
        );
      } catch (err) {
        notify.error(err.message);
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            quickSearch: {
              ...prev.quickSearch,
              isFetching: false,
            },
          })),
        );
      }
    };
  }
}

export class Selectors {
  public static isEmpty(state: AppState) {
    return state.departments.positions.length === 0 && state.departments.departments.length === 0;
  }

  public static allItemsCount(state: AppState) {
    return state.departments.positions.length + state.departments.departments.length;
  }
}

export const reducer = stateController.getReducer();
