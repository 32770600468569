import { useDispatch } from 'react-redux';
import { ReactNode, useState, useRef } from 'react';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import useDisableDocumentScroll from 'hooks/use-disable-document-scroll';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { usePopupState, bindFocus, bindMenu, bindTrigger } from 'material-ui-popup-state/hooks';
import { ExtendButtonBase, IconButton, Menu, MenuItem, MenuItemTypeMap, styled, Tooltip } from '@mui/material';
import s from './dropdown-menu.module.scss';

export enum ActionsModeEnum {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
}

export type DropdownMenuItemFunc = () => {
  key: string;
  render: () => ReactNode;
  onClick: (...params: any) => any;
};
export type DropdownMenuItemObj = {
  key: string;
  onClick: VoidFunction;
  text: string | ReactNode;
  icon?: ReactNode;
  hoverColor?: string;
  defaultColor?: string;
  bottomDivider?: boolean;
  containerClassName?: string;
};
export type DropdownMenuItem = DropdownMenuItemFunc | DropdownMenuItemObj;

type Props = {
  id: string;
  disabled?: boolean;
  isPermitted?: boolean;
  buttonIcon?: ReactNode;
  buttonClassName?: string;
  items: DropdownMenuItem[];
  wrapperClassName?: string;
  menuItemClassName?: string;
  position?: 'center' | 'left' | 'right';
  disabledItems?: { [key: string]: string };
  ButtonComponent?: React.ComponentType<any>;
  renderButton?: (props) => ReactNode;
};

const DEFAULT_COLOR = '#141414';
const HOVER_COLOR = '#F1F5F9';

export const MenuItemStyled = styled<ExtendButtonBase<MenuItemTypeMap<{ hovercolor?: string; defaultcolor?: string }, 'li'>>>(
  MenuItem,
)(({ defaultcolor, hovercolor }) => {
  return {
    color: defaultcolor || DEFAULT_COLOR,
    '& svg *': {
      stroke: defaultcolor || DEFAULT_COLOR,
    },
    '&:hover': {
      backgroundColor: hovercolor || HOVER_COLOR,
    },
  };
});

function DropdownMenu({
  id,
  items,
  disabled,
  buttonIcon,
  wrapperClassName,
  isPermitted = true,
  disabledItems = {},
  position = 'center',
  buttonClassName = '',
  menuItemClassName = '',
  renderButton,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  useDisableDocumentScroll(isOpen, 'dropdownMenu');

  const rootRef = useRef<HTMLDivElement>(null);

  const popupState = usePopupState({ variant: 'dialog', popupId: `actions-${id}` });
  const toggleProps = bindFocus(popupState);

  const dispatch = useDispatch();

  const handleOnClick = () => {
    if (!isPermitted) {
      dispatch(PermissionGuardActions.openModal());
      return;
    }
    setIsOpen(true);
  };

  return (
    <div
      ref={rootRef}
      className={`
          ${s.dropdown_menu}
          ${wrapperClassName}
        `}
      data-disabled={disabled}
      {...bindTrigger(popupState)}
      onClick={(event) => isPermitted && event.stopPropagation()}
    >
      {typeof renderButton === 'function' ? (
        renderButton({
          onFocus: (event) => {
            event.stopPropagation();
            toggleProps.onFocus(event);
          },
          onBlur: (event) => {
            toggleProps.onBlur(event);
          },
          // ...bindTrigger(popupState),
        })
      ) : (
        <IconButton size="small" className={buttonClassName} onClick={handleOnClick}>
          {buttonIcon || <MoreVertOutlinedIcon />}
        </IconButton>
      )}
      <div
        onClick={(event) => {
          event.stopPropagation();
          setIsOpen(false);
        }}
      >
        <Menu
          {...bindMenu(popupState)}
          key={id}
          open={isOpen}
          anchorEl={rootRef.current}
          transformOrigin={{
            vertical: 'top',
            horizontal: position,
          }}
          disableAutoFocusItem
          classes={{ paper: s.menu_container }}
        >
          {items.map((menuItem, index) => {
            const isLastItem = items.length < index + 2;
            return typeof menuItem === 'function' ? (
              <div
                key={menuItem().key}
                onClick={(event) => {
                  event.stopPropagation();
                  setIsOpen(false);
                  menuItem().onClick();
                }}
              >
                {menuItem().render()}
              </div>
            ) : (
              <Tooltip key={menuItem.key} title={disabledItems[menuItem.key] || ''} placement="top-start">
                <div>
                  <div key={menuItem.key} className={menuItem.key in disabledItems ? s.disabled : ''}>
                    <MenuItemStyled
                      sx={menuItem.defaultColor ? { color: menuItem.defaultColor } : {}}
                      className={`${s.menu_item} ${menuItem.containerClassName || ''} ${menuItemClassName}`}
                      onClick={(event) => {
                        event.stopPropagation();
                        setIsOpen(false);
                        menuItem.onClick();
                      }}
                      hovercolor={menuItem.hoverColor || HOVER_COLOR}
                      defaultcolor={menuItem.defaultColor || DEFAULT_COLOR}
                    >
                      {menuItem.icon && <div className={s.icon_container}>{menuItem.icon}</div>}
                      {menuItem.text}
                    </MenuItemStyled>
                    {menuItem.bottomDivider && !isLastItem && <div className={s.divider} />}
                  </div>
                </div>
              </Tooltip>
            );
          })}
        </Menu>
      </div>
    </div>
  );
}

export default DropdownMenu;
