export const COLORS = {
  '#FFF2F9': {
    textColor: '#64172B',
    bgColor: '#FFF2F9',
  },
  '#F9F1FE': {
    textColor: '#402060',
    bgColor: '#F9F1FE',
  },
  '#FFF3F2': {
    textColor: '#5C271F',
    bgColor: '#FFF3F2',
  },
  '#EBF6FF': {
    textColor: '#113264',
    bgColor: '#EBF6FF',
  },
  '#E9F7EE': {
    textColor: '#0D3D38',
    bgColor: '#E9F7EE',
  },
  '#FFF3E1': {
    textColor: '#473B1F',
    bgColor: '#FFF3E1',
  },
  '#F0F6DD': {
    textColor: '#4C5442',
    bgColor: '#F0F6DD',
  },
  '#FDFAE0': {
    textColor: '#473B1F',
    bgColor: '#FDFAE0',
  },
  '#EEFDFF': {
    textColor: '#0D3C48',
    bgColor: '#EEFDFF',
  },
  '#FFEDEE': {
    textColor: '#5C271F',
    bgColor: '#FFEDEE',
  },
  '#EAF0FF': {
    textColor: '#272962',
    bgColor: '#EAF0FF',
  },
  '#FFEFF7': {
    textColor: '#621639',
    bgColor: '#FFEFF7',
  },
  '#F4FBF7': {
    textColor: '#1D3B31',
    bgColor: '#F4FBF7',
  },
  '#FFF6FF': {
    textColor: '#651249',
    bgColor: '#FFF6FF',
  },
  '#EDF5FB': {
    textColor: '#1D3E56',
    bgColor: '#EDF5FB',
  },
};

export const MAX_INPUT_LENGTH = 255;
