import { FC, SVGProps } from 'react';

export const Barcode2Icon: FC<SVGProps<SVGSVGElement>> = ({ stroke = '#141414', ...props }) => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.5971 9.77018C10.2194 9.77018 9.91579 9.65199 9.68636 9.41562C9.45926 9.17924 9.3457 8.8687 9.3457 8.48401V8.27544H10.0062V8.48401C10.0062 8.67172 10.0595 8.82351 10.1661 8.93939C10.2727 9.05294 10.4164 9.10972 10.5971 9.10972C10.7779 9.10972 10.9216 9.05294 11.0282 8.93939C11.1348 8.82351 11.1881 8.67172 11.1881 8.48401V7.94173C11.1881 7.75402 11.1348 7.60339 11.0282 7.48983C10.9216 7.37396 10.7779 7.31603 10.5971 7.31603H10.1452V6.65556H10.5971C10.7779 6.65556 10.9216 6.59878 11.0282 6.48523C11.1348 6.36936 11.1881 6.21757 11.1881 6.02985V5.61967C11.1881 5.43196 11.1348 5.28132 11.0282 5.16777C10.9216 5.0519 10.7779 4.99396 10.5971 4.99396C10.4164 4.99396 10.2727 5.0519 10.1661 5.16777C10.0595 5.28132 10.0062 5.43196 10.0062 5.61967V5.82824H9.3457V5.61967C9.3457 5.23266 9.45926 4.92212 9.68636 4.68806C9.91579 4.45168 10.2194 4.3335 10.5971 4.3335C10.9772 4.3335 11.2808 4.45168 11.5079 4.68806C11.735 4.92212 11.8485 5.23266 11.8485 5.61967V6.02985C11.8485 6.27087 11.8022 6.48291 11.7095 6.66599C11.6191 6.84906 11.4905 6.9939 11.3236 7.10051L11.3271 6.86065C11.4916 6.96957 11.6191 7.11673 11.7095 7.30212C11.8022 7.4852 11.8485 7.6984 11.8485 7.94173V8.48401C11.8485 8.8687 11.735 9.17924 11.5079 9.41562C11.2808 9.65199 10.9772 9.77018 10.5971 9.77018Z"
      fill={stroke}
    />
    <path
      d="M6.25 9.65895V9.07843C6.25 8.81193 6.29403 8.57671 6.38209 8.37277C6.47247 8.16884 6.58719 7.98692 6.72623 7.82702C6.86759 7.66712 7.01591 7.52112 7.17118 7.38903C7.32876 7.25462 7.47708 7.12484 7.61612 6.9997C7.75749 6.87456 7.8722 6.74594 7.96026 6.61385C8.04832 6.47943 8.09236 6.33228 8.09236 6.17238V5.61967C8.09236 5.43196 8.03905 5.28132 7.93245 5.16777C7.82585 5.0519 7.68217 4.99396 7.50141 4.99396C7.32065 4.99396 7.17697 5.0519 7.07037 5.16777C6.96377 5.28132 6.91047 5.43196 6.91047 5.61967V5.82824H6.25V5.61967C6.25 5.23266 6.36355 4.92212 6.59066 4.68806C6.82009 4.45168 7.12367 4.3335 7.50141 4.3335C7.88147 4.3335 8.18505 4.45168 8.41216 4.68806C8.63927 4.92444 8.75282 5.23498 8.75282 5.61967V6.17238C8.75282 6.37863 8.70879 6.56634 8.62073 6.73551C8.53498 6.90468 8.42375 7.06227 8.28702 7.20827C8.15029 7.35195 8.00429 7.48983 7.84903 7.62193C7.69608 7.7517 7.55124 7.8838 7.41451 8.01821C7.27778 8.1503 7.16538 8.29051 7.07732 8.43882C6.98926 8.58482 6.94523 8.74588 6.94523 8.922V9.29743L6.64628 8.99848H8.75282V9.65895H6.25Z"
      fill={stroke}
    />
    <path
      d="M4.74389 9.65904V5.70319H4V5.04272H4.1599C4.3453 5.04272 4.48898 4.99637 4.59094 4.90368C4.69291 4.81098 4.74389 4.68352 4.74389 4.5213V4.44482H5.40436V9.65904H4.74389Z"
      fill={stroke}
    />
    <path
      d="M11.332 12.6668H4.66536C2.66536 12.6668 1.33203 11.6668 1.33203 9.3335V4.66683C1.33203 2.3335 2.66536 1.3335 4.66536 1.3335H11.332C13.332 1.3335 14.6654 2.3335 14.6654 4.66683V9.3335C14.6654 11.6668 13.332 12.6668 11.332 12.6668Z"
      stroke={stroke}
      strokeWidth="1.1"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
